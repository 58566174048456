.heading {
  text-align: center;
  font-size: clamp(2.75rem, 10 * (1vw) / 2, 4.75rem);
  color: #2f2f37;
  margin-top: clamp(3rem, 10 * (1vw) / 2, 6.5rem);
  margin-bottom: clamp(1rem, 10 * (1vw) / 2, 3.75rem);
  letter-spacing: -0.1rem;

  /*TODO*/
}

.metaDataText {
  font-size: 0.75rem;
  text-transform: uppercase;
  font-stretch: 25%;
  font-weight: 400;
  letter-spacing: 0.03rem;
  font-variation-settings: "grad" -200;
  align-self: center;
}

/* Not sure if needed */
.postMetaText {
  font-size: 0.75rem;
  text-transform: uppercase;
  font-stretch: 25%;
  letter-spacing: 0.03rem;
  font-variation-settings: "grad" -200;
  font-weight: 700;
}
