.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 7rem;
}

@media screen and (min-width: 48rem) {
  .headerContainer {
    flex-direction: row;
    height: 80px;
    align-items: center;
  }
}

.logoLink {
  display: block;
  width: 80px;
  height: 80px;
  position: relative;
  top: 0;
}

.logo {
  width: 80px;
  height: 80px;
  top: 0;
  display: block;
  position: relative;
}

@media screen and (min-width: 48rem) {
  .nav {
    flex-grow: 1;
  }
}

.navList {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

.navItem {
  display: flex;
  align-items: center;
}

.navItem:not(:nth-last-child(1))::after {
  content: "\25CF";
  font-size: 0.25rem;
}

@media screen and (min-width: 48rem) {
  .navItem::after,
  .navItem:first-of-type::before,
  .navItem:not(:nth-last-child(1))::after {
    content: "\25CF";
    font-size: 0.5rem;
  }
}

.navLink {
  font-size: 0.975rem;
  padding: 0 0.75rem;
  text-decoration: none;
}

@media screen and (min-width: 48rem) {
  .navLink {
    font-size: 1rem;
    padding: 0 1.5rem;
  }
}

.navLink,
.navLink:visited {
  color: #2f2f37;
}

.navLink:hover {
  color: #782ed5;
}

@media screen and (max-width: 48rem) {
  .contactLink {
    display: none;
  }
}
